<template>
  <button
    class="c-button c-chat__button"
    :class="{ active: chatStore.chatOpened }"
    @click="toggleChatDialog">
    <SvgIcon
      medium
      type="chat_bubble" />
    <span>{{ $t('chat.messages') }}</span>
  </button>
</template>

<script setup>
import { useChatStore } from '@/modules/chat/chatStore'

const chatStore = useChatStore()

const toggleChatDialog = () => {
  chatStore.chatOpened = !chatStore.chatOpened
}

</script>
