<template>
  <div class="c-chat__messagesContainer">
    <DynamicScroller
      v-show="chatThreadStore.sortedMessages && !chatThreadStore.chatThreadIsOpening"
      ref="messagesScroller"
      :items="chatThreadStore.sortedMessages"
      :min-item-size="96"
      class="c-chat__messagesScroller">
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[
            item.content,
          ]"
          :data-index="index"
          :data-message-id="item.id"
          class="c-chat__messageRow"
          :class="{ '-me': isMe(item.senderDisplayName) }">
          <div>
            <div class="o-flex -alignCenter -gap4">
              <button
                v-if="isMe(item.senderDisplayName) && item.state === 'error'"
                class="c-button -flat -icon -tiny">
                <SvgIcon
                  small
                  type="chat_resend" />
              </button>
              <Message
                :key="item.id"
                :message="item" />
            </div>
            <ReadReceipt
              :chat-thread="props.chatThread"
              :message="item" />
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
    <LoadingSpinner v-if="chatThreadStore.chatThreadIsOpening" />
  </div>
</template>

<script setup>

import {
  defineProps, ref, onMounted, onBeforeUnmount,
} from 'vue'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useChatStore } from '@/modules/chat/chatStore'
import { useChatThreadStore } from '@/modules/chat/chatThreadStore'
import Message from '@/modules/chat/components/Message.vue'
import ReadReceipt from '@/modules/chat/components/ReadReceipt.vue'
import store from '@/store'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

const props = defineProps({
  chatThread: {
    type: Object,
    required: true,
  },
})

const chatStore = useChatStore()
const chatThreadStore = useChatThreadStore(props.chatThread.azure_chat_thread_id)

let olderMessagesAreLoading = false
const messagesScroller = ref()

function updateScrollbarPosition(messageId) {
  const newItemIndex = messagesScroller.value.items.findIndex((item) => item.id === messageId)
  return messagesScroller.value.scrollToItem(newItemIndex)
}

function isMe(userId) {
  return parseInt(userId) === store.getters['auth/userId']
}

function setReadReceiptForNewestMessage() {
  const newestMessageId = chatThreadStore.sortedMessages[chatThreadStore.sortedMessages.length - 1].id.toString()

  if (!chatThreadStore.myReadReceipt || chatThreadStore.myReadReceipt.chatMessageId !== newestMessageId) {
    // It sets a reading receipt when a user open a chat thread and last messages have been fetched
    chatThreadStore.setReadReceipt(newestMessageId)
  }
}
async function init() {
  await chatThreadStore.init(messagesScroller)
  setReadReceiptForNewestMessage()
}
init()

async function onScroll() {
  if (olderMessagesAreLoading || chatThreadStore.chatThreadIsOpening || chatThreadStore.allMessagesAreLoaded) {
    return
  }
  if (messagesScroller.value.$el.scrollTop > 0) {
    return
  }

  olderMessagesAreLoading = true

  // we always want to scroll to the message which was on the top message before loading messages
  const messageId = chatThreadStore.sortedMessages[0].id

  await chatThreadStore.loadMoreMessages()
  await updateScrollbarPosition(messageId)

  olderMessagesAreLoading = false
}

onMounted(async () => {
  chatStore.openAzureChatThreadId = props.chatThread.azure_chat_thread_id
  messagesScroller.value.$el.addEventListener('scroll', onScroll)
})

onBeforeUnmount(() => {
  chatStore.openAzureChatThreadId = undefined
  messagesScroller.value.$el.removeEventListener('scroll', onScroll)
})
</script>
