export default [
  {
    component: () => import('@/modules/profile/views/ManageProfile.vue'),
    name: 'profile',
    path: '/profile',
  },
  {
    component: () => import('@/modules/profile/views/ChangePassword.vue'),
    name: 'password',
    path: '/profile/password',
  },
  {
    component: () => import('@/modules/profile/views/NotificationSettings.vue'),
    name: 'settings',
    path: '/profile/notification-settings',
  },
  {
    component: () => import('@/modules/profile/views/ManageVouchers.vue'),
    name: 'vouchers',
    path: '/profile/vouchers',
    meta: {
      requiresModule: 'vouchers',
    },
  },
  {
    component: () => import('@/modules/profile/views/ChangeProfileSettings.vue'),
    name: 'profile-settings',
    path: '/profile/settings',
  },
  {
    component: () => import('@/modules/profile/views/ConvertAccount.vue'),
    name: 'convertAccount',
    path: '/profile/convertAccount',
  },
  {
    component: () => import('@/modules/profile/views/CompleteAccount.vue'),
    name: 'completeAccount',
    path: '/profile/completeAccount',
  },
  {
    component: () => import('@/modules/profile/views/ChangeAvatar.vue'),
    name: 'avatar',
    path: '/profile/avatar',
  },
  {
    component: () => import('@/modules/profile/views/Certificates.vue'),
    name: 'certificates',
    path: '/profile/certificates',
  },
]
