import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

import accountApi from '@/modules/account/api/account'

export const useOnlineUserSessionStore = defineStore('onlineUserSession', {
  state: () => ({
    sessionId: useStorage('userOnlineSession', Date.now().toString()),
    isOnline: undefined,
    isProcessing: false,
    wbtIsOpen: false,
  }),
  actions: {
    async setOnlineStatus() {
      if (this.isProcessing) {
        return
      }

      this.isProcessing = true
      try {
        await accountApi.setOnlineStatus(this.sessionId)
      } catch (error) {
        console.error('Failed to set online status:', error)
      } finally {
        this.isOnline = true
        this.isProcessing = false
      }
    },
    async setOfflineStatus() {
      if (this.isProcessing || this.wbtIsOpen) {
        return
      }

      this.isProcessing = true
      try {
        await accountApi.setOfflineStatus(this.sessionId)
      } catch (error) {
        console.error('Failed to set offline status:', error)
      } finally {
        this.isOnline = false
        this.isProcessing = false
      }
    },
  },
})
