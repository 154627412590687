<template>
  <div
    v-if="show"
    ref="emojiDropdown"
    class="emoji-dropdown"
    :style="{ top: position.top, left: position.left }">
    <button
      v-for="(emoji, index) in filteredEmojis"
      :key="emoji.id"
      class="emoji-item"
      :class="{ selected: index === selectedIndex }"
      @focus="selectedIndex = index"
      @mouseover="selectedIndex = index"
      @click="selectEmoji()">
      <span>
        {{ emoji.skins[0].native }}
      </span>
      <span class="emoji-name">:{{ emoji.id }}:</span>
    </button>
  </div>
</template>

<script setup>
import emojiData from '@emoji-mart/data'
import {
  ref, watch, computed, onMounted, onBeforeUnmount, nextTick,
} from 'vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  query: {
    type: String,
    default: '',
  },
  position: {
    type: Object,
    default: () => ({ top: '0px', left: '0px' }),
  },
})

const emit = defineEmits(['select'])

const emojiDropdown = ref(null)
const selectedIndex = ref(-1)

const filteredEmojis = computed(() => {
  if (!props.query) return []

  const searchQuery = props.query.toLowerCase()
  return Object.values(emojiData.emojis)
    .filter((emoji) => {
      const nameMatch = emoji.name.toLowerCase().includes(searchQuery)
      const keywordMatch = emoji.keywords.some((keyword) => keyword.toLowerCase().includes(searchQuery))
      return nameMatch || keywordMatch
    })
})

const scrollToSelected = (direction) => {
  nextTick(() => {
    const dropdown = emojiDropdown.value
    const selectedItem = dropdown?.querySelectorAll('.emoji-item')[selectedIndex.value]

    if (selectedItem) {
      const dropdownRect = dropdown.getBoundingClientRect()
      const selectedRect = selectedItem.getBoundingClientRect()

      if (direction === 'down') {
        if (selectedRect.bottom > dropdownRect.bottom) {
          dropdown.scrollTop += selectedRect.bottom - dropdownRect.bottom
        }
      } else if (direction === 'up') {
        if (selectedRect.top < dropdownRect.top) {
          dropdown.scrollTop -= dropdownRect.top - selectedRect.top
        }
      }
    }
  })
}

const selectEmoji = (skinIndex = 0) => {
  const selectedEmoji = filteredEmojis.value[selectedIndex.value]
  if (selectedEmoji && selectedEmoji.skins.length > 0) {
    emit('select', selectedEmoji.skins[skinIndex].native)
  }
}

const handleKeyDown = (event) => {
  if (!props.show) return

  if (event.key === 'ArrowDown') {
    event.preventDefault()
    if (selectedIndex.value === filteredEmojis.value.length - 1) {
      return
    }
    selectedIndex.value = (selectedIndex.value + 1) % filteredEmojis.value.length
    scrollToSelected('down')
  } else if (event.key === 'ArrowUp') {
    event.preventDefault()
    if (selectedIndex.value - 1 < 0) {
      return
    }
    selectedIndex.value = (selectedIndex.value - 1 + filteredEmojis.value.length) % filteredEmojis.value.length
    scrollToSelected('up')
  } else if (event.key === 'Enter' && selectedIndex.value >= 0) {
    event.preventDefault()
    selectEmoji()
  }
}

watch(() => props.show, (newValue) => {
  if (!newValue) {
    selectedIndex.value = -1
  }
})

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyDown)
})

defineExpose({
  emojiDropdown,
  selectEmoji,
})
</script>

<style lang="scss" scoped>
.emoji-dropdown {
  position: absolute;
  z-index: 10;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  width: 250px;
  bottom: 20px;
}

.emoji-item {
  border: none;
  background: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;

  &:hover,
  &.selected {
    background-color: #f5f5f5;
  }

  .emoji-name {
    font-size: 14px;
    color: #666;
  }
}
</style>
