import { useAzureChatStore } from '@/modules/chat/azureChatStore'
import store from '@/store'

export default {
  async getChatThreadClient(chatThreadId) {
    const azureChatStore = useAzureChatStore()
    const chatClient = await azureChatStore.getChatClient()
    return chatClient.getChatThreadClient(chatThreadId)
  },

  async getChatThreads() {
    const azureChatStore = useAzureChatStore()
    const chatClient = await azureChatStore.getChatClient()
    const chatThreadIterator = chatClient.listChatThreads()

    const chatThreads = []
    for await (const chatThread of chatThreadIterator) {
      chatThreads.push(chatThread)
    }

    return chatThreads
  },
  async sendMessage(chatThreadId, message) {
    const chatThreadClient = await this.getChatThreadClient(chatThreadId)

    const sendMessageOptions = {
      senderDisplayName: store.getters['auth/userId'].toString(),
      type: 'text',
    }
    return chatThreadClient.sendMessage(
      { content: message },
      sendMessageOptions,
    )
  },
  async getReadReceipts(chatThreadId) {
    const chatThreadClient = await this.getChatThreadClient(chatThreadId)
    const chatThreadReadReceiptIterator = chatThreadClient.listReadReceipts()

    const readReceipts = []
    for await (const readReceipt of chatThreadReadReceiptIterator) {
      readReceipts.push({
        chatMessageId: readReceipt.chatMessageId,
        readOn: readReceipt.readOn,
        sender: readReceipt.sender,
      })
    }

    return readReceipts
  },
  async setReadingEvent(chatThreadId, chatMessageId) {
    const chatThreadClient = await this.getChatThreadClient(chatThreadId)
    return chatThreadClient.sendReadReceipt({ chatMessageId })
  },
  async setTypingEvent(chatThreadId) {
    const chatThreadClient = await this.getChatThreadClient(chatThreadId)
    return chatThreadClient.sendTypingNotification({ senderDisplayName: store.getters['auth/userId'].toString() })
  },

}
