<template>
  <div
    id="app"
    :class="{
      '-noMobileFooter': $route.meta.noMobileFooter,
      '-lessMobileTopPadding': $route.meta.noMobileMenus,
      '-noMobileBottomPadding': $route.meta.noMobileBottomPadding,
      '-lightBackground': hasLightBackground,
    }"
    class="o-app__wrapper">
    <div class="o-app">
      <DefaultSidenav />
      <div class="o-mainContent__nextToSidenav">
        <div
          :style="wrapperStyle"
          class="o-mainContent__wrapper">
          <portal-target
            name="banner"
            slim />
          <div
            class="o-mainContent"
            :class="{ '-noSidebar': $route.meta.noSidebar }"
            data-cy="DefaultLayout__mainContent">
            <MobileHeader v-show="!$route.meta.noMobileMenus" />
            <OfflineIndicator />
            <router-view v-show="!isLoading" />
            <LoadingIndicator />
          </div>
          <MobileMenu v-show="!$route.meta.noMobileMenus && !$route.meta.noMobileFooter" />
          <div class="o-bottomNavigation">
            <portal-target name="mobile-bottom" />
          </div>
          <DefaultSidebar />
        </div>
        <portal-target name="footer" />
      </div>
    </div>
    <portal-target
      multiple
      name="modal" />
    <NotificationsList />
    <KeywordModal />
    <ChatButton />
    <transition name="bounce">
      <ChatDialog v-if="chatStore.chatOpened" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LoadingIndicator from '@/components/LoadingIndicator.vue'
import OfflineIndicator from '@/components/OfflineIndicator.vue'
import DefaultSidebar from '@/layouts/partials/DefaultSidebar.vue'
import DefaultSidenav from '@/layouts/partials/DefaultSidenav.vue'
import MobileHeader from '@/layouts/partials/MobileHeader.vue'
import MobileMenu from '@/layouts/partials/MobileMenu.vue'
import NotificationsList from '@/layouts/partials/NotificationsList.vue'
import { useChatStore } from '@/modules/chat/chatStore'
import ChatButton from '@/modules/chat/components/ChatButton.vue'
import ChatDialog from '@/modules/chat/components/ChatDialog.vue'
import KeywordModal from '@/modules/keywords/components/KeywordModal.vue'

export default {
  setup() {
    const chatStore = useChatStore()
    return {
      chatStore,
    }
  },
  created() {
    if (window.Capacitor) {
      window.Capacitor.Plugins.SplashScreen.hide()
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isLoading: 'isLoading',
      config: 'auth/config',
    }),
    hasLightBackground() {
      return this.config.settings.tablet_light_background
    },
    wrapperStyle() {
      const style = {}
      if (this.$route.meta.contentBackgroundColor && window.innerWidth >= 1024) {
        style.background = this.$route.meta.contentBackgroundColor
      }
      return style
    },
  },
  components: {
    ChatDialog,
    ChatButton,
    MobileHeader,
    NotificationsList,
    DefaultSidebar,
    DefaultSidenav,
    MobileMenu,
    LoadingIndicator,
    OfflineIndicator,
    KeywordModal,
  },
}
</script>
