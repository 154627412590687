<template>
  <div>
    <button
      class="c-chat__inputButton c-chat__emojiButton"
      @click="toggleEmojiPicker">
      <SvgIcon
        small
        type="chat_emoji" />
    </button>
    <Picker
      v-if="showEmojiPicker"
      ref="emojiPicker"
      class="emoji-picker"
      :style="pickerStyle"
      @select="insertEmoji" />
  </div>
</template>

<script setup>
import { Picker } from 'emoji-mart-vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'

const props = defineProps({
  editorRef: {
    type: Object,
    require: true,
  },
})

const showEmojiPicker = ref(false)

const pickerStyle = ref({
  position: 'absolute', bottom: '60px', right: '20px', zIndex: 1000,
})

const toggleEmojiPicker = () => {
  showEmojiPicker.value = !showEmojiPicker.value
}
const handleClickOutside = (event) => {
  const emojiPicker = document.querySelector('.emoji-picker')

  if (!emojiPicker?.contains(event.target) && !event.target.classList.contains('c-chat__emojiButton')) {
    showEmojiPicker.value = false
  }
}
const handleKeyDown = (event) => {
  if (event.key === 'Escape') {
    showEmojiPicker.value = false
  }
}

const insertEmoji = (event) => {
  const emoji = event.native
  props.editorRef.editor.chain()
    .focus()
    .insertContent(emoji)
    .run()
  showEmojiPicker.value = false
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
  document.addEventListener('keydown', handleKeyDown)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
  document.removeEventListener('keydown', handleKeyDown)
})
</script>
