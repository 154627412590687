module.exports = {
  ask_button: 'Fragen',
  ask_community_button: 'Ja, Community fragen',
  ask_community_prompt: 'Möchten Sie diese Frage in der Community stellen?',
  listening_placeholder: 'Ich höre zu...',
  question_placeholder: 'Stellen Sie Ihre Frage...',
  start_listening: 'Spracherkennung starten',
  stop_listening: 'Spracherkennung stoppen',
  create_post: {
    title: 'Create Post',
    community_label: 'Community',
    select_community: 'Select a community',
    content_label: 'Post',
    content_placeholder: 'How can I optimize my picking process?',
    submitting: 'Creating...',
    submit: 'Beitrag erstellen',
  },
  ask_community: 'Community fragen',
  ask_community_loading: 'Lade Community-Daten...',
  ask_community_error: 'Fehler beim Laden der Community-Daten',
}
