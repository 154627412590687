import Vue from 'vue'
import VueRouter from 'vue-router'

import { setLoginRedirect } from '@/logic/loginRedirect'
import appointments from '@/modules/appointments/routes'
import askkeelearning from '@/modules/askkeelearning/routes'
import auth from '@/modules/auth/routes'
import chat from '@/modules/chat/routes'
import competitions from '@/modules/competitions/routes'
import courses from '@/modules/courses/routes'
import feedback from '@/modules/feedback/routes'
import global from '@/modules/global/routes'
import learningmaterials from '@/modules/learningmaterials/routes'
import news from '@/modules/news/routes'
import pages from '@/modules/pages/routes'
import powerlearning from '@/modules/powerlearning/routes'
import profile from '@/modules/profile/routes'
import quizzes from '@/modules/quizzes/routes'
import teams from '@/modules/teams/routes'
import tests from '@/modules/tests/routes'
import todolists from '@/modules/todolists/routes'
import viewers from '@/modules/viewers/routes'
import webinars from '@/modules/webinars/routes'
import store from '@/store'

const moduleRoutes = {
  appointments,
  askkeelearning,
  auth,
  chat,
  competitions,
  courses,
  feedback,
  learningmaterials,
  news,
  pages,
  powerlearning,
  profile,
  quizzes,
  teams,
  tests,
  todolists,
  viewers,
  webinars,
}

Vue.use(VueRouter)

let routes = global

const modules = Object.keys(moduleRoutes)
function attachParentRouteNames(route, parent) {
  route.name = `${parent}.${route.name}`
  if (route.children) {
    route.children = route.children.map((child) => attachParentRouteNames(child, route.name))
  }
  return route
}
modules.forEach((module) => {
  routes = routes.concat(moduleRoutes[module].map((route) => attachParentRouteNames(route, module)))
})

// 404s redirect to the home page
routes.push({
  path: '*',
  redirect: '/',
})

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        to.matched[0].instances.default.$once('loaded', () => {
          resolve({ selector: to.hash })
        })
      })
    }
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  store.commit('stopLoading')
  if (typeof to.meta.requiresAuth === 'undefined' || to.meta.requiresAuth !== false) {
    if (!store.getters['auth/isLoggedIn']) {
      if (to.query.tmpLoginToken) {
        store.dispatch('auth/tmpLogin', {
          tmpLoginToken: to.query.tmpLoginToken,
        }).then(() => {
          next()
        }).catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e)
          setLoginRedirect(to.fullPath)
          next('/auth/login')
        })
        return null
      }
      setLoginRedirect(to.fullPath)
      return next('/auth/login')
    }
  }
  if (typeof to.meta.requiresModule !== 'undefined' && to.meta.requiresModule) {
    if (!store.getters['auth/hasModule'][to.meta.requiresModule]) {
      return next(`/missing-module/${to.meta.requiresModule}`)
    }
  }
  return next()
})

router.afterEach((to) => {
  // Reload the page after navigating to the new site to activate the new app version
  const refreshRoutes = [
    'home',
    'learning',
    'training',
    'suggest-question',
    'courses.dashboard',
    'feedback.create',
    'learningmaterials.dashboard',
    'news',
    'powerlearning.dashboard',
    'quizzes.overview',
    'quizzes.statistics',
    'teams.mine',
    'tests.dashboard',
    'webinars.dashboard',
  ]
  if (store.getters.isUpdateAvailable) {
    if (to && refreshRoutes.includes(to.name)) {
      store.commit('setUpdateAvailable', false)
      // eslint-disable-next-line no-console
      console.log('Reloading app because update is available')
      window.location.reload.bind(window.location)()
    }
  }
})

if (typeof window.Capacitor !== 'undefined') {
  window.Capacitor.Plugins.App.addListener('appUrlOpen', (event) => {
    window.Capacitor.Plugins.Browser.close()
    const path = `/${event.url.split('//')[1]}`
    router.push({
      path,
    })
  })
}

export default router
