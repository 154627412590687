import { relaunchApi } from '@/logic/relaunchApi'

export default {
  async getChatThread(participantId) {
    const response = await relaunchApi.post('/chat/threads', { participantId })
    return response.data
  },
  async updatePushNotification(body) {
    const response = await relaunchApi.post('/chat/push-subscription', body)
    return response.data
  },
  async notifyAboutNewMessage(body) {
    const response = await relaunchApi.post('/chat/notifications/new-message', body)
    return response.data
  },
  async searchMessages(chatThreadId, query) {
    const response = await relaunchApi.get(`/chat/threads/${encodeURIComponent(chatThreadId)}/search-messages`, {
      query,
    })
    return response.data
  },
}
