import { ChatClient } from '@azure/communication-chat'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { isPast, parseISO } from 'date-fns'
import { defineStore } from 'pinia'

import { relaunchApi } from '@/logic/relaunchApi'

export const useAzureChatStore = defineStore('azureChatStore', {
  state: () => ({
    azureChatClient: null,
    azureChatClientPromise: null,
    accessToken: undefined,
    myIdentity: undefined,
  }),
  actions: {
    async fetchCredentials() {
      const response = await relaunchApi.get('/chat/credentials')
      this.accessToken = response.data.accessToken
      this.myIdentity = response.data.identity.id
    },
    async loadCredentials() {
      if (!this.accessToken || this.myIdentity) {
        await this.fetchCredentials()
        return
      }
      const expiresOn = parseISO(this.accessToken.expiresOn)

      if (isPast(expiresOn)) {
        await this.fetchCredentials()
      }
    },
    async getChatClient() {
      if (this.azureChatClientPromise) {
        return this.azureChatClientPromise
      }
      if (this.azureChatClient) {
        return this.azureChatClient
      }
      this.azureChatClientPromise = new Promise((resolve, reject) => {
        this.loadCredentials().then(async () => {
          const endpointUrl = process.env.VUE_APP_AZURE_COMMUNICATION_ENDPOINT
          this.azureChatClient = await new ChatClient(endpointUrl, new AzureCommunicationTokenCredential(this.accessToken))
          return this.azureChatClient
        })
          .then(() => {
            resolve(this.azureChatClient)
            this.azureChatClientPromise = null
          })
          .catch((error) => {
            this.azureChatClient = null
            this.azureChatClientPromise = null
            reject(error)
          })
      })

      return this.azureChatClientPromise
    },
  },
})
