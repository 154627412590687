import api from '@/logic/api'

export const REQUEST_ABORTED = 'aborted'

export const relaunchApi = {
  lastConnectionErrorShown: null,
  router: null,
  store: null,
  i18n: null,
  setup(store, i18n, router) {
    this.store = store
    this.i18n = i18n
    this.router = router
  },
  send(url, method, params, body, abortController = null, keepalive = false) {
    const headers = {
      ...this.getDefaultHeaders(),
    }
    return new Promise((resolve, reject) => {
      const requestInit = {
        headers,
        method,
        keepalive,
      }
      if (abortController) {
        requestInit.signal = abortController.signal
      }
      if (body instanceof FormData) {
        requestInit.body = body
        if ('Content-Type' in requestInit.headers) {
          delete requestInit.headers['Content-Type']
        }
      } else if (body) {
        requestInit.body = JSON.stringify(body)
      }
      fetch(relaunchApi.getURL(url, params), requestInit).then(
        (response) => {
          if (!response.ok) {
            if (response.headers.get('x-logout-user')) {
              this.store.dispatch('auth/logout')
            } else if (response.headers.get('x-tos-not-accepted')) {
              if (this.router.app.$route.meta.requiresAuth === false) {
                return false
              }
              this.router.push({
                name: 'auth.tos',
              })
            } else if (response.headers.get('x-password-not-reset')) {
              this.router.push({
                name: 'auth.forcepasswordreset',
              })
            } else {
              this.getErrorMessage(response).then(reject)
            }
            return false
          }

          switch (response.headers.get('content-type')) {
            case 'application/json':
              return response.json().then(resolve).catch(reject)
            default:
              resolve(response)
          }
          return true
        },
        (error) => {
          if (
            abortController
            && abortController.signal.aborted
          ) {
            reject(REQUEST_ABORTED)
            return
          }
          this.handleConnectionError()
          reject(error)
        },
      )
    })
  },
  get(url, params) {
    return this.send(url, 'GET', params)
  },
  post(url, body, abortController = null) {
    return this.send(url, 'POST', undefined, body, abortController)
  },
  postAndForget(url, body, abortController = null) {
    return this.send(url, 'POST', undefined, body, abortController, true)
  },
  delete(url, body) {
    return this.send(url, 'DELETE', undefined, body)
  },
  getErrorMessage(response) {
    try {
      return response.json().then((res) => {
        if (res.error) {
          return res.error
        }
        if (res.message) {
          return res.message
        }
        return this.i18n.t('errors.no_response')
      })
    } catch (e) {
      return Promise.resolve(this.i18n.t('errors.unknown_error'))
    }
  },
  handleConnectionError() {
    if (window.navigator.onLine) {
      const currentDate = Date.now()
      if (
        this.lastConnectionErrorShown
        && currentDate - this.lastConnectionErrorShown < 3000
      ) {
        return false
      }
      this.lastConnectionErrorShown = currentDate
      this.store.commit('notifications/addNotification', {
        topic: this.i18n.t('notifications.error'),
        title: 'connection error',
      })
    }
    return true
  },
  getURL(path, params = null) {
    let parsedParams = ''
    if (params !== null && Object.keys(params).length > 0) {
      parsedParams += '?'
      parsedParams += Object.keys(params)
        .map(
          (k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`,
        )
        .join('&')
    }
    let domain = process.env.VUE_APP_RELAUNCH_API_URL
    if (path.indexOf('http://') === 0 || path.indexOf('https://') === 0) {
      domain = ''
    }
    return domain + path + parsedParams
  },
  getDefaultHeaders() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-LANGUAGE': this.i18n.locale,
      ...api.getAPIVersionHeader(),
    }
    if (this.getToken()) {
      headers['X-QUIZAPP-AUTHORIZATION'] = `Bearer ${this.getToken()}`
    }
    return headers
  },
  getToken() {
    return this.store.state.auth.token
  },
}
