import { applyConfig } from '@/logic/appConfig'
import { resetLoginRedirect } from '@/logic/loginRedirect'
import { useAzureChatStore } from '@/modules/chat/azureChatStore'
import ProfileAPI from '@/modules/profile/api/profile'
import TmpLoginTokenAPI from '@/modules/viewers/api/tmpLoginToken'

export default {
  setConfig({ commit }, config) {
    commit('setConfig', config)

    applyConfig(config)
  },
  async logout({ commit, rootGetters }) {
    try {
      await ProfileAPI.logout(rootGetters['auth/fcmToken'])

      const azureChatStore = useAzureChatStore()
      const chatClient = await azureChatStore.getChatClient()
      chatClient.dispose()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
    commit('logout')
    resetLoginRedirect()
    // We redirect (reload) here, because the logout mutation also deletes the config and we need to basically restart the app at this point
    window.location.href = '/'
  },
  updateProfile({ commit }) {
    return ProfileAPI.getProfile().then((data) => {
      commit('setProfileData', data)
    })
  },
  tmpLogin({ commit }, { tmpLoginToken }) {
    return TmpLoginTokenAPI.loginViaTmpToken(tmpLoginToken).then((loginData) => {
      commit('login', loginData)
    })
  },
}
