<template>
  <div
    v-if="readReceipt"
    class="t-caption2">
    read at
    {{ readOn }}
  </div>
</template>

<script setup>
import { format } from 'date-fns'
import { computed, defineProps } from 'vue'

import { useChatThreadStore } from '@/modules/chat/chatThreadStore'

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
  chatThread: {
    type: Object,
    required: true,
  },
})

const chatThreadStore = useChatThreadStore(props.chatThread.azure_chat_thread_id)

const readReceipt = computed(() => {
  if (!chatThreadStore.partnerReadReceipt) {
    return null
  }
  if (chatThreadStore.partnerReadReceipt.chatMessageId !== props.message.id.toString()) {
    return null
  }

  return chatThreadStore.partnerReadReceipt
})

const readOn = computed(() => {
  if (!readReceipt.value) {
    return null
  }
  const date = new Date(readReceipt.value.readOn)

  return format(date, 'dd.MM.y HH:mm')
})
</script>
