import { useOnlineUserSessionStore } from '@/modules/account/onlineUserSessionStore'
import store from '@/store'

let timeoutForEventActivity

function setOnlineStatus() {
  const onlineUserSessionStore = useOnlineUserSessionStore()
  if (!onlineUserSessionStore.isOnline && store.getters['auth/isLoggedIn']) {
    onlineUserSessionStore.setOnlineStatus()
  }
}
function setOfflineStatus() {
  const onlineUserSessionStore = useOnlineUserSessionStore()

  // the full screen mode is mainly used while watching videos
  const isFullScreenMode = !!document.fullscreenElement

  if (!onlineUserSessionStore.isOnline || isFullScreenMode) {
    return
  }

  onlineUserSessionStore.setOfflineStatus()
}
function mouseAndKeyboardEventHandler() {
  setOnlineStatus()

  clearTimeout(timeoutForEventActivity)
  // set the offline status after 5 minutes of non activity
  timeoutForEventActivity = setTimeout(() => {
    setOfflineStatus()
  }, 5 * 60 * 1000)
}

function confirmUserIsStillOnline() {
  setInterval(() => {
    setOnlineStatus()
  }, 30 * 60 * 1000)
}

function setWindowListeners() {
  // It's important to call window.focus() on page load,
  // otherwise it won't be listening for blur event unless the user interacts with the document.
  window.addEventListener('load', () => {
    window.focus()
  })
  window.addEventListener('focus', () => {
    setOnlineStatus()
  })
  window.addEventListener('blur', () => {
    clearTimeout(timeoutForEventActivity)
    setOfflineStatus()
  })
}
function setDocumentListeners() {
  document.addEventListener('mousemove', mouseAndKeyboardEventHandler, { passive: true })
  document.addEventListener('keydown', mouseAndKeyboardEventHandler, { passive: true })
  document.addEventListener('mousedown', mouseAndKeyboardEventHandler, { passive: true })
  document.addEventListener('touchstart', mouseAndKeyboardEventHandler, { passive: true })
  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      setOnlineStatus()
    } else {
      setOfflineStatus()
    }
  })
}
export function initOnlineStatusSetup() {
  setOnlineStatus()
  setWindowListeners()
  setDocumentListeners()
  confirmUserIsStillOnline()
}
