<template>
  <div
    ref="actionContainer"
    class="c-chat__dialogMainContentMessageActions o-flex -start">
    <div>
      <button class="c-chat__inputButton">
        <SvgIcon
          small
          type="chat_microphone" />
      </button>
    </div>
    <div class="o-flex__item -grow">
      <TipTapEditor
        ref="editor"
        v-model="message"
        input-class="c-chat__messageInput"
        :enter-key-handler="enterKeyHandler"
        @input="typingEvent" />
    </div>
    <EmojiPicker :editor-ref="editor" />
    <div v-if="!message">
      <button class="c-chat__inputButton">
        <SvgIcon
          small
          type="chat_attachment" />
      </button>
    </div>
    <div :class="{ 'u-hide@tabletMiniDown': !message }">
      <button
        class="c-chat__inputButton -send"
        @click="sendMessage">
        <SvgIcon
          small
          type="chat_send" />
        <span class="t-button1 c-chat__inputButtonText">Send</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

import EmojiPicker from '@/components/EmojiPicker.vue'
import TipTapEditor from '@/components/TipTapEditor.vue'
import azureChatApi from '@/modules/chat/api/azureChat'
import chatApi from '@/modules/chat/api/chat'
import { useChatStore } from '@/modules/chat/chatStore'
import { getParticipant } from '@/modules/chat/logic/chatThread'

const editor = ref(null)
const actionContainer = ref(null)
const message = ref('')
const isTyping = ref(false)
const chatStore = useChatStore()

let timeoutIdForTyping = null
let intervalIdForRequest = null

function typingEvent() {
  isTyping.value = true

  clearTimeout(timeoutIdForTyping)
  timeoutIdForTyping = setTimeout(() => {
    isTyping.value = false
  }, 2000)
}

watch(isTyping, async () => {
  if (!isTyping.value) {
    clearInterval(intervalIdForRequest)
    return
  }

  intervalIdForRequest = setInterval(() => {
    if (isTyping.value) {
      azureChatApi.setTypingEvent(chatStore.chatThread.azure_chat_thread_id)
    }
  }, 1000)
})

async function sendMessage() {
  const sendMessageResult = await azureChatApi.sendMessage(chatStore.chatThread.azure_chat_thread_id, message.value)
  chatApi.notifyAboutNewMessage({
    body: message.value,
    chatThreadId: chatStore.chatThread.id,
    participantId: getParticipant(chatStore.chatThread)?.user_id,
    messageId: sendMessageResult.id,
  })
  azureChatApi.setReadingEvent(chatStore.chatThread.azure_chat_thread_id, sendMessageResult.id)
  message.value = ''
  isTyping.value = false
}

const enterKeyHandler = async () => {
  await sendMessage()
}
</script>
