<template>
  <div
    class="c-chat__dialog"
    :class="{ opened: chatStore.chatOpened }">
    <div
      class="c-chat__dialogContent"
      :class="{ '-hasThreadOpened': chatStore.chatThread }">
      <div class="c-chat__dialogSidebar">
        <div class="c-chat__dialogSidebarHeader">
          <div
            class="c-chat__dialogSidebarHeaderInputWrapper"
            :class="{ '-onboarding-state': !chatStore.usersList.length && !isUsersSearchQueryInputFocused }">
            <button
              class="o-flex c-button -icon -tiny -flat c-chat__searchIcon"
              @click="focusUserSearchInput">
              <SvgIcon
                type="chat_search"
                small />
            </button>
            <input
              ref="usersSearchQueryRef"
              v-model="chatStore.usersSearchQuery"
              type="text"
              :placeholder="$t('chat.search')"
              class="c-chat__dialogSidebarHeaderInput"
              @focus="openShowUsersResult"
              @blur="closeShowUsersResult" />
          </div>
          <UsersSearchResult :show="showUsersSearchResult" />
        </div>
        <div class="c-chat__dialogSidebarContent">
          <template v-for="user in chatStore.usersList">
            <UsersListItem
              :key="user.id"
              :message="{ text: 'Du: Hi Matthias, vielleicht kannst du mir weiterhelfen hiasd sda dasd asdasdasdas', time: '12:00', type: 'image' }"
              :user="user"
              @click="openThread(user)" />
          </template>
        </div>
      </div>
      <div
        class="c-chat__dialogMain">
        <div class="c-chat__dialogMainHeader o-flex -alignCenter -spaceBetween">
          <div class="o-flex -alignCenter -gap4">
            <div v-if="chatStore.userDetails">
              <button
                class="c-chat__dialogBackButton -primary-border"
                @click="backToChatThread">
                <SvgIcon
                  tiny
                  type="chat_arrow_left" />
              </button>
            </div>
            <div
              v-if="!chatStore.userDetails"
              :class="{ 'u-hide@tabletMiniDown': isChatSearchQueryInputFocused }"
              class="u-hide@tabletPortrait">
              <button
                class="c-chat__dialogBackButton -primary-border"
                @click="backToUsersList">
                <SvgIcon
                  tiny
                  type="chat_arrow_left" />
              </button>
            </div>
            <button
              v-if="!chatStore.userDetails && chatStore.chatThread"
              class="c-chat__dialogMainHeaderUser c-button -flat o-flex -alignCenter -gap2 s-p0"
              :class="{ 'u-hide@tabletMiniDown': isChatSearchQueryInputFocused }"
              @click="openUserDetails">
              <UserAvatar
                :user="chatStore.usersList[0]"
                small />
              <span
                class="t-body3"
                v-html="chatStore.usersList[0].name" />
            </button>
          </div>

          <div
            ref="chatSearchQueryWrapperRef"
            class="c-chat__dialogMainSearchWrapper o-flex -end -spaceBetween -gap3 o-flex__item">
            <div
              class="o-flex -alignCenter -spaceBetween -gap2 o-flex__item">
              <button
                v-if="chatSearchInputFocused"
                class="c-button -flat s-p0"
                @click="handleChatSearchBlur">{{ $t('chat.cancel') }}
              </button>
              <div
                v-if="chatStore.chatThread"

                class="c-chat__dialogSidebarHeaderInputWrapper -rounded -thick-border"
                :class="{ '-onboarding-state@tabletMiniDown -no-animation': !chatSearchInputFocused }">
                <button
                  class="o-flex c-button -icon -tiny -flat c-chat__searchIcon"
                  @click="focusChatSearchInput">
                  <SvgIcon
                    small
                    type="chat_search" />
                </button>
                <input
                  ref="chatSearchQueryRef"
                  v-model="chatStore.chatSearchQuery"
                  type="text"
                  :placeholder="$t('chat.search_chat')"
                  class="c-chat__dialogSidebarHeaderInput"
                  @focus="handleChatSearchFocus"
                  @blur="handleChatSearchBlur" />
              </div>
            </div>
            <button
              class="c-chat_closeBtn c-button -flat -icon -transparent -tiny u-marginRight1"
              @click="closeChatDialog">
              <SvgIcon
                medium
                type="chat_minus" />
            </button>
          </div>
        </div>
        <div class="c-chat__dialogMainContent">
          <template v-if="!chatStore.userDetails">
            <div
              key="content-wrapper"
              class="c-chat__dialogMainContentWrapper">
              <ChatWelcome v-if="!chatStore.chatThread" />
              <MessagesContainer
                v-else
                :chat-thread="chatStore.chatThread"
                @chatMessageReceived="resetTypingState" />
              <LastMessagesButton />
            </div>
            <ChatMessageActions
              v-if="chatStore.chatThread"
              key="message-actions" />
          </template>
          <template v-else>
            <Details key="user-details" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, ref } from 'vue'
import { useRoute } from 'vue-router/composables'

import { useChatStore } from '@/modules/chat/chatStore'
import ChatMessageActions from '@/modules/chat/components/ChatMessageActions.vue'
import ChatWelcome from '@/modules/chat/components/ChatWelcome.vue'
import UserAvatar from '@/modules/chat/components/DialogSidebar/UserAvatar.vue'
import UsersListItem from '@/modules/chat/components/DialogSidebar/UsersListItem.vue'
import UsersSearchResult from '@/modules/chat/components/DialogSidebar/UsersSearchResult.vue'
import LastMessagesButton from '@/modules/chat/components/LastMessagesButton.vue'
import MessagesContainer from '@/modules/chat/components/MessagesContainer.vue'
import Details from '@/modules/chat/components/UserProfile/Details.vue'

const chatStore = useChatStore()
const route = useRoute()
const isUsersSearchQueryInputFocused = ref(false)
const isChatSearchQueryInputFocused = ref(false)
const showUsersSearchResult = ref(false)
const usersSearchQueryRef = ref(null)
const chatSearchQueryRef = ref(null)
const chatSearchQueryWrapperRef = ref(null)
const chatSearchInputFocused = ref(false)

const handleChatSearchFocus = () => {
  chatSearchInputFocused.value = true
  isChatSearchQueryInputFocused.value = true
  chatSearchQueryWrapperRef.value?.classList.add('-grow')
  chatSearchQueryWrapperRef.value?.querySelector('.o-flex').classList.add('-grow')
}

const handleChatSearchBlur = () => {
  chatSearchInputFocused.value = false
  isChatSearchQueryInputFocused.value = false
  chatSearchQueryWrapperRef.value?.classList.remove('-grow')
  chatSearchQueryWrapperRef.value?.querySelector('.o-flex').classList.remove('-grow')
}

const openShowUsersResult = () => {
  showUsersSearchResult.value = true
  isUsersSearchQueryInputFocused.value = true
}

const closeShowUsersResult = () => {
  nextTick().then(() => {
    showUsersSearchResult.value = false
    isUsersSearchQueryInputFocused.value = false
  })
}

const focusUserSearchInput = () => {
  isUsersSearchQueryInputFocused.value = true
  nextTick().then(() => usersSearchQueryRef.value?.focus())
}

const focusChatSearchInput = () => {
  handleChatSearchFocus()
  nextTick().then(() => chatSearchQueryRef.value?.focus())
}

const closeChatDialog = () => {
  chatStore.chatOpened = false
}

const openThread = () => {
  chatStore.userDetails = null
  chatStore.chatThread = chatStore.myChatThreads.find((myChatThread) => myChatThread.id === parseInt(route.params.chatThreadId))
}

const openUserDetails = () => {
  chatStore.userDetails = chatStore.usersList[0]
}

const backToChatThread = () => {
  chatStore.userDetails = null
}

const backToUsersList = () => {
  chatStore.userDetails = null
  chatStore.chatThread = null
}

const typingState = ref(null)

function resetTypingState() {
  typingState.value = null
}
chatStore.fetchMyChatThreads()
</script>

<style scoped lang="scss">
.s-p0 {
  padding: 0;
}
</style>
