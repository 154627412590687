<template>
  <div class="c-chat__userDetailsWrapper">
    <div class="c-chat__userDetails">
      <div class="t-alignCenter">
        <UserAvatar
          :user="chatStore.userDetails"
          large />
        <h1 class="text-h1 u-marginTop2 u-marginBottom0">{{ chatStore.userDetails.name }}</h1>
        <h5 class="text-h5 u-marginTop4 u-marginBottom3">{{ chatStore.userDetails.username }}</h5>
        <a
          class="t-body3 t-button1"
          :href="'mailto:' + chatStore.userDetails.email">{{ chatStore.userDetails.email }}</a>
        <div class="t-body3 u-marginTop3">Department Head</div>
        <div class="t-caption1 u-marginTop4">Active since 01.01.2020</div>
        <div class="t-caption1 u-marginTop1">11:04 AM (CET)</div>

        <div class="c-chat__mediaTabs u-marginTop6">
          <div
            ref="chatTabActiveStateRef"
            class="c-chat__mediaTabActiveState"
            :style="{ transform: `translateX(${activeTabPosition}px)`, width: activeTabWidth + 'px' }" />
          <button
            v-for="(tab, index) in tabs"
            :key="tab.key"
            class="c-chat__mediaTabItem c-button -flat t-body3"
            :class="{ '-active': activeMediaTab === tab.key }"
            @click="changeMediaTab(tab.key, index, $event)">
            <SvgIcon
              small
              class="c-chat__mediaTabItemIcon"
              :type="tab.icon" />
            <span class="t-body3">{{ tab.name }}</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Sliding Tab Content -->
    <div class="c-chat__mediaFilesWrapper">
      <div
        class="c-chat__mediaFiles"
        :style="{ transform: `translateX(-${activeTabIndex * 100}%)` }">
        <div class="c-chat__mediaFilesItem">
          <UserImages
            v-if="activeMediaTab === 'IMAGES'"
            key="user-images" />
        </div>
        <div class="c-chat__mediaFilesItem">
          <UserFiles
            v-if="activeMediaTab === 'FILES'"
            key="user-files" />
        </div>
        <div class="c-chat__mediaFilesItem">
          <UserLinks
            v-if="activeMediaTab === 'LINKS'"
            key="user-links" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref, computed, onMounted, nextTick,
} from 'vue'

import { useChatStore } from '@/modules/chat/chatStore'
import UserAvatar from '@/modules/chat/components/DialogSidebar/UserAvatar.vue'
import UserFiles from '@/modules/chat/components/UserProfile/UserFiles.vue'
import UserImages from '@/modules/chat/components/UserProfile/UserImages.vue'
import UserLinks from '@/modules/chat/components/UserProfile/UserLinks.vue'

const chatStore = useChatStore()

const tabs = ref([
  { key: 'IMAGES', name: 'Images', icon: 'chat_images' },
  { key: 'FILES', name: 'Files', icon: 'chat_file' },
  { key: 'LINKS', name: 'Links', icon: 'chat_links' },
])

const activeMediaTab = ref('IMAGES')
const activeTabIndex = computed(() => tabs.value.findIndex((tab) => tab.key === activeMediaTab.value))

const activeTabPosition = ref(0)
const activeTabWidth = ref(0)
const chatTabActiveStateRef = ref(null)

const updateTabIndicator = (element) => {
  activeTabWidth.value = element.offsetWidth
  activeTabPosition.value = element.offsetLeft
}

const changeMediaTab = async (key, index, event) => {
  activeMediaTab.value = key
  await nextTick()
  updateTabIndicator(event.target)
}

onMounted(() => {
  const activeTab = document.querySelector('.c-chat__mediaTabItem.-active')
  if (activeTab) {
    updateTabIndicator(activeTab)
  }
})
</script>
