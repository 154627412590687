module.exports = {
  certificates: {
    downloadCertificate: 'Zertifikat herunterladen',
    searchCertificates: 'Zertifikate durchsuchen..',
    toCourses: 'Zu den Kursen',
    youHaveNoCertificates: 'Du hast noch keine Zertifikate erhalten. Starte jetzt einen Kurs!',
  },
  openid: {
    generic_login_error: 'Anmeldung über OpenID Connect fehlgeschlagen.',
    no_sso_question: 'Kein Single-Sign-on?',
    please_wait: 'Bitte habe einen Moment Geduld, wir loggen dich ein...',
    use_default_login: 'Standard-Login',
  },
  login: {
    forgotpassword: 'Passwort vergessen?',
    login: 'Einloggen',
    mail: 'E-Mail',
    mail_or_username: 'E-Mail / Username',
    reset_password: 'Passwort zurücksetzen',
  },
  logout: {
    message: 'Du wirst abgemeldet...',
  },
  convert: {
    button_convert: 'Konvertieren!',
    description: 'Hier kannst du deinen temporären Account in einen normalen Account umwandeln.<br>Dadurch kannst du auch auf anderen Geräten spielen.',
    title: 'Account umwandeln',
    create_full_account: 'Jetzt Account anlegen',
    benefits_title: 'Die Vorteile eines Accounts:',
    benefits1: 'Nutzung auf mehreren Geräten: logge dich überall mit denselben Benutzerdaten ein und führe deine Lernreise einfach weiter',
    benefits2: 'Lernstatistiken dauerhaft speichern',
    benefits3: 'E-Mail Benachrichtigung zu News, neuen Dateien oder Quiz-Battles Herausforderungen',
    request_failed: 'Dein Account konnte leider nicht umgewandelt werden',
  },
  password: {
    button_change_password: 'Passwort ändern',
    new_repeat: 'Neues Passwort wiederholen',
    new: 'Neues Passwort',
    previous: 'Aktuelles Passwort',
    success: 'Das Passwort wurde erfolgreich geändert.',
    request_failed: 'Dein Passwort konnte leider nicht geändert werden',
    we_will_send_you_a_new_one: 'Kein Problem. Wir senden dir ein neues.',
    set_new_password: 'Neues Passwort speichern',
    fill_all_fields: 'Bitte fülle alle Felder aus',
    passwords_dont_match: 'Die neuen Passwörter stimmen nicht überein',
  },
  profile: {
    certificates: 'Zertifikate',
    max_file_size: 'Das Bild darf maximal %{size} groß sein.',
    invalid_avatar: 'Dieses Bild ist ungültig',
    warning: 'Achtung',
    warning_description: 'Sobald du dich ausloggst, gehen all deine Daten verloren. Lege jetzt einen Account an um deine Quiz-Battles nicht zu verpassen, deinen Fortschritt zu speichern und an Gewinnspielen und Challenges teilzunehmen.',
    change_avatar: 'Profilbild ändern',
    upload_new_avatar: 'Neues Profilbild hochladen',
    avatar_saving: 'Avatar wird gespeichert...',
    existing_avatars: 'Standard Avatare',
    slug: 'App-ID',
  },
  resetpassword: {
    reset_button: 'Neues Passwort anfordern',
    mail_sent: 'E-Mail für dich!',
    mail_sent_info: 'Wir haben dir eine E-Mail mit einem neuen Passwort an %{userEmail} gesendet.',
    didnt_receive_mail_description: 'Sieh bitte auch in deinem Spam Ordner nach oder fordere erneut ein neues Passwort an. Wende dich an %{adminEmail} falls es weiterhin nicht klappen sollte.',
  },
  signup: {
    signup: 'Account erstellen',
    optional: 'Optional',
    create_account: 'Account anlegen',
    start_with_your_account: 'Leg jetzt mit deinem Lern-Account los.',
    firstname_description: '',
    lastname_description: '',
    username_description: 'Mit deinem Benutzernamen bist Du innerhalb der App z.B. über die Quiz-Challenge auffindbar.',
    password_description: 'Mindestens 8 Zeichen, Groß- und Kleinschreibung, Zahlen und Symbole.',
    abort: 'Registrierung abbrechen',
    confirm_abort: 'Möchtest Du die Registrierung wirklich abbrechen?',
    voucher_description: 'Den Code erhältst Du vom App-Anbieter. Mit ihm schaltest Du die Lerninhalte frei.',
  },
  temporary: {
    try_without_account: 'App testen ohne Account',
    guest_account: 'Gastzugang',
    description: 'Wirf einen Blick auf die Inhalte ohne einen Account anzulegen.',
    try_now: 'Jetzt testen',
    notes_title: 'Hinweis zum Testen ohne Account',
    notes: 'Deine Daten verfallen sobald du dich ausloggst oder der Browser Cache gelöscht wird.\n              Mit einem Account kannst du deinen Fortschritt speichern und dich über verschiedene Endgeräte einloggen.',
  },
  tos: {
    title: 'Nutzungsbedingungen',
    accept: 'Nutzungsbedingungen akzeptieren',
  },
  activate: {
    activation_successful: 'Du hast deinen Account erfolgreich aktiviert!',
    activate_your_account: 'Aktiviere deinen Account',
    didnt_receive_mail: 'Du hast die E-Mail nicht erhalten?',
    mail_sent_info: 'Wir haben dir eine E-Mail an %{email} gesendet. Bitte klicke auf den Link in der Email um deinen Account zu aktivieren.',
    didnt_receive_mail_description: 'Sieh bitte auch in deinem Spam Ordner nach. Wende dich an %{adminEmail} falls es weiterhin nicht klappen sollte.',
    go_to_home: 'Weiter zur Startseite',
  },
  slogan: 'Wir machen Lernen<br>einfach einfach.',
  words: 'Kurz. Spaßig. Mobil.',
  signup_are_you_new: 'Du bist neu hier?',
  back_to_login: 'Zurück zum Login',
  complete: {
    complete_now: 'Account vervollständigen',
    complete_successful: 'Du hast einen vollständigen Account und kannst nun auf alle Funktionen zugreifen!',
    request_failed: 'Dein Account konnte leider nicht aktualisiert werden',
  },
  temporary_account: 'Temporärer Account',
  select_app: {
    title: 'App-ID',
    appid_not_found: 'Wir konnten keine Anmeldeseite für die von Ihnen eingegebene App-ID finden. Bitte prüfen Sie die Schreibweise.',
    help_please: 'Ich kann meine App-ID nicht finden',
    next_step: 'Weiter zur Anmeldeseite',
    is_being_set: 'App wird konfiguriert...',
    appid_label: 'App-ID',
    explanation: 'Mit Ihren Anmeldedaten wurde Ihnen eine App-ID zugesendet. Bitte geben Sie diese ein um zur Anmeldeseite zu gelangen.',
    modal_title: 'Wie finde ich meine App ID?',
    modal_explanation: 'Die App-ID ist in der Registrierungsbestätigung enthalten. Über die Webseite der App kann die App-ID zudem im Profil eingesehen werden.',
  },
  new_username: 'Benutzername',
  force_password_reset: {
    title: 'Neues Passwort vergeben',
    explanation: 'Bitte vergib ein neues Passwort, mit welchem Du Dich künftig einloggen kannst.',
    info: 'Mindestens 8 Zeichen, Groß- und Kleinschreibung, Zahlen und Symbole.',
    send: 'Speichern',
  },
  delete_account: {
    title: 'Accountlöschung beantragen',
    content: 'Möchtest du beantragen, dass dein Account gelöscht wird?',
    success: 'Deine Anfrage wurde gesendet',
    confirm_deletion: 'Löschung beantragen',
    request_failed: 'Die Anfrage konnte leider nicht abgesendet werden. Bitte wende dich direkt an deinen Administrator.',
    request_account_deletion: 'Accountlöschung beantragen',
  },
}
