<template>
  <button
    class="c-button -flat c-chat__usersColleagueCard"
    @click="openThread(user)">
    <span class="c-chat__usersColleagueCardImageWrapper">
      <UserAvatar :user="user" />
    </span>
    <span class="c-chat__usersColleagueCardName">
      <span class="t-h6">{{user.name}}</span>
    </span>
  </button>
</template>

<script setup>
import UserAvatar from '@/modules/chat/components/DialogSidebar/UserAvatar.vue'

defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const openThread = (user) => {
  console.log(user)
}

</script>
