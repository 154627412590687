import { defineStore } from 'pinia'

import { relaunchApi } from '@/logic/relaunchApi'
import azureChat from '@/modules/chat/api/azureChat'
import { useChatThreadStore } from '@/modules/chat/chatThreadStore'

export const useChatStore = defineStore('chatStore', {
  state: () => ({
    myChatThreads: [],
    openAzureChatThreadId: undefined,
    chatOpened: false,
    chatThread: null,
    userDetails: null,
    usersSearchQuery: '',
    chatSearchQuery: '',
    usersSearchResult: [
      {
        id: 1, name: 'Anita Ahrens', image: 'https://cdn-icons-png.flaticon.com/512/6997/6997662.png', status: 1,
      },
      {
        id: 2, name: 'Tobias-Manuel Heinrich', image: 'https://cdn-icons-png.flaticon.com/128/3135/3135715.png', status: 0,
      },
      {
        id: 3, name: 'Tobias-Manuel Heinrich', image: 'https://cdn-icons-png.flaticon.com/128/3135/3135715.png', status: -1,
      },
    ],
    usersList: [
      {
        id: 1, name: 'Anita Ahrens', email: 'matthias.behrenz@firma-x.de', username: 'Anita Ahrens', image: 'https://cdn-icons-png.flaticon.com/512/6997/6997662.png', status: 1,
      },
      {
        id: 2, name: 'Tobias-Manuel Heinrich', email: 'matthias.behrenz@firma-x.de', username: 'Tobias-Manuel Heinrich', image: 'https://cdn-icons-png.flaticon.com/128/3135/3135715.png', status: 0,
      },
    ],
    colleagues: [
      {
        id: 1, name: 'Anita Ahrens', image: 'https://cdn-icons-png.flaticon.com/512/6997/6997662.png', status: 1,
      },
      {
        id: 2, name: 'Tobias-Manuel Heinrich', image: 'https://cdn-icons-png.flaticon.com/128/3135/3135715.png', status: 0,
      },
      {
        id: 3, name: 'Tobias-Manuel Heinrich', image: 'https://cdn-icons-png.flaticon.com/128/3135/3135715.png', status: -1,
      },
    ],
  }),
  actions: {
    async fetchMyChatThreads() {
      const response = await relaunchApi.get('/chat/threads')
      this.myChatThreads = response.data
      this.fetchLastMessages()
    },
    async fetchLastMessages() {
      // fetch last messages which are saved in MongoDB
      const response = await relaunchApi.get('/chat/threads/last-messages')

      this.myChatThreads = this.myChatThreads.map((thread) => {
        const message = response.data.find((item) => item.azure_chat_thread_id === thread.azure_chat_thread_id)

        if (message) {
          message.created_at = new Date(message.created_at)
          return { ...thread, message }
        }

        return thread
      })
      const azureChatThreads = await azureChat.getChatThreads()

      // fetch the newest messages from Azure if messages from MongoDB aren't up to date
      this.myChatThreads = await Promise.all(
        this.myChatThreads.map(async (thread) => {
          const foundAzureChatThread = azureChatThreads.find((azureChatThread) => thread.azure_chat_thread_id === azureChatThread.id)
          if (!thread.message || (foundAzureChatThread && foundAzureChatThread.lastMessageReceivedOn > thread.message.created_at)) {
            const chatThreadStore = useChatThreadStore(thread.azure_chat_thread_id)
            const message = await chatThreadStore.getLastMessage()
            return { ...thread, message }
          }

          return thread
        }),
      )
    },
  },
})
