import { useAzureChatStore } from '@/modules/chat/azureChatStore'
import { useChatStore } from '@/modules/chat/chatStore'
import { useChatThreadStore } from '@/modules/chat/chatThreadStore'

export async function listenToChatEvents() {
  const azureChatStore = useAzureChatStore()
  const chatClient = await azureChatStore.getChatClient()
  await chatClient.startRealtimeNotifications()
  const chatStore = useChatStore()

  chatClient.on('chatMessageReceived', async (newMessage) => {
    const chatThreadStore = useChatThreadStore(newMessage.threadId)
    chatThreadStore.messages.push({
      id: parseInt(newMessage.id),
      content: newMessage.message,
      senderDisplayName: newMessage.senderDisplayName,
      createdOn: newMessage.createdOn,
    })
    chatThreadStore.typingIndicator = undefined
    chatThreadStore.updateSortedMessages()
    await chatThreadStore.scrollContainerDown()
    if (chatStore.openAzureChatThreadId === newMessage.threadId) {
      // It sets a reading receipt when a user receives a new message for an open chat thread
      chatThreadStore.setReadReceipt(newMessage.id)
    }
  })

  chatClient.on('readReceiptReceived', (newReadReceipt) => {
    const chatThreadStore = useChatThreadStore(newReadReceipt.threadId)
    chatThreadStore.partnerReadReceipt = {
      chatMessageId: newReadReceipt.chatMessageId,
      readOn: newReadReceipt.readOn,
      sender: { ...newReadReceipt.sender },
    }
  })

  chatClient.on('typingIndicatorReceived', (typingEvent) => {
    const chatThreadStore = useChatThreadStore(typingEvent.threadId)
    if (typingEvent.sender.communicationUserId === typingEvent.recipient.communicationUserId) {
      return
    }

    chatThreadStore.typingIndicator = {
      senderDisplayName: typingEvent.senderDisplayName,
      receivedOn: typingEvent.receivedOn,
    }

    clearTimeout(chatThreadStore.typingIndicatorTimeout)
    chatThreadStore.typingIndicatorTimeout = setTimeout(() => {
      chatThreadStore.typingIndicator = null
    }, 2000)
  })
}
