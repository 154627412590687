<template>
  <span
    class="c-chat__userIndicator"
    :class="[indicatorColorClass, small ? '-small' : '', large ? '-large' : '']" />
</template>

<script setup>
import { computed } from 'vue'

import constants from '@/constants'

const props = defineProps({
  status: {
    type: Number,
    required: true,
  },
  // To set small size of the indicator
  small: {
    type: Boolean,
    require: false,
    default: false,
  },
  large: {
    type: Boolean,
    require: false,
    default: false,
  },
})

const indicatorColorClass = computed(() => {
  const statusClasses = {
    [constants.CHAT.USER_STATUSES.IDLE]: '-gray',
    [constants.CHAT.USER_STATUSES.OFFLINE]: '-red',
    [constants.CHAT.USER_STATUSES.ONLINE]: '-green',
  }
  return statusClasses[props.status] ?? ''
})
</script>
