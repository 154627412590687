import { defineStore } from 'pinia'

import azureChatApi from '@/modules/chat/api/azureChat'
import { useAzureChatStore } from '@/modules/chat/azureChatStore'

export const useChatThreadStore = (azureChatThreadId) => {
  if (!azureChatThreadId) {
    throw new Error('azureChatThreadId is required')
  }
  const store = defineStore(`chatThreadStore-${azureChatThreadId}`, {
    state: () => ({
      azureChatThreadId,
      messages: [],
      sortedMessages: [],
      chatThreadIsOpening: true,
      initialMessagesHaveBeenLoaded: false,
      allMessagesAreLoaded: false,
      sequenceIdOfFirstMessage: undefined,
      partnerReadReceipt: undefined,
      myReadReceipt: undefined,
      messageIterator: undefined,
      typingIndicator: undefined,
      typingIndicatorTimeout: null,
      messagesScroller: null,
    }),
    actions: {
      async initializeMessageIterator() {
        if (this.messageIterator) {
          return
        }
        const chatThreadClient = await azureChatApi.getChatThreadClient(this.azureChatThreadId)
        this.messageIterator = chatThreadClient.listMessages({ maxPageSize: 20 }).byPage()
      },
      async init(messagesScroller) {
        this.messagesScroller = messagesScroller
        this.allMessagesAreLoaded = false
        this.chatThreadIsOpening = true

        await this.initializeMessageIterator()

        // call loadMoreMessages only when a chat thread is opening first time
        if (!this.initialMessagesHaveBeenLoaded) {
          // reset received messages for an open chat thread
          this.messages = []
          this.sortedMessages = []
          await this.loadMoreMessages()
        }

        if (!this.partnerReadReceipt || !this.myReadReceipt) {
          await this.loadReadReceipts()
        }

        this.chatThreadIsOpening = false
        await this.scrollContainerDown()
      },
      updateSortedMessages() {
        this.sortedMessages = [...this.messages].filter((message) => message.content).sort((a, b) => {
          return a.id - b.id
        })
      },
      async loadMoreMessages() {
        if (!this.messageIterator) {
          throw Error('the message iterator is not initialized')
        }
        let countOfMessagesToGet = 20

        const pushMessage = (message) => {
          const sequenceId = parseInt(message.sequenceId)
          // we don't want to fetch the newest messages here which were added after initial loading a chat thread.
          //  These new messages are loaded using the "chatMessageReceived" event.
          if (message.type === 'text' && (!this.sequenceIdOfFirstMessage || sequenceId < this.sequenceIdOfFirstMessage)) {
            this.messages.push({
              id: parseInt(message.id),
              content: message.content.message,
              senderDisplayName: message.senderDisplayName,
              createdOn: message.createdOn,
            })
            countOfMessagesToGet--
          }
          if (!this.sequenceIdOfFirstMessage) {
            this.sequenceIdOfFirstMessage = sequenceId
          }
        }

        while (countOfMessagesToGet > 0) {
          // await is necessary here to fetch messages by a request
          // the .next() call of the second run depends on the .next() call of the first run
          // eslint-disable-next-line no-await-in-loop
          const { done, value: page } = await this.messageIterator.next()

          if (done) {
            this.allMessagesAreLoaded = true
            break
          }
          page.forEach((message) => {
            pushMessage(message)
          })
        }

        this.initialMessagesHaveBeenLoaded = true

        await this.updateSortedMessages()
      },
      async getLastMessage() {
        if (!this.sortedMessages.length) {
          await this.initializeMessageIterator()
          await this.loadMoreMessages()
        }

        if (!this.sortedMessages.length) {
          return null
        }

        const lastMessage = this.sortedMessages[this.sortedMessages.length - 1]

        return {
          azure_chat_thread_id: this.azureChatThreadId,
          azure_message_id: lastMessage.id,
          message: lastMessage.content,
          azure_sender_id: lastMessage.senderDisplayName,
          created_at: lastMessage.createdOn,
        }
      },
      async loadReadReceipts() {
        const azureChatStore = useAzureChatStore()
        const azureReadReceipts = await azureChatApi.getReadReceipts(this.azureChatThreadId)

        if (!azureReadReceipts.length) {
          return
        }

        this.partnerReadReceipt = azureReadReceipts.find((readReceipt) => {
          return readReceipt.sender.communicationUserId !== azureChatStore.myIdentity
        })
        this.myReadReceipt = azureReadReceipts.find((readReceipt) => {
          return readReceipt.sender.communicationUserId === azureChatStore.myIdentity
        })
      },
      setReadReceipt(newMessageId) {
        azureChatApi.setReadingEvent(this.azureChatThreadId, newMessageId)
      },
      scrollContainerDown() {
        if (this.messagesScroller) {
          this.messagesScroller.scrollToBottom()
        }
      },
    },
  })

  return store()
}
