<template>
  <div
    class="c-chat__usersSearchResult"
    :class="{ '-show': show }">
    <div
      v-if="chatStore.usersSearchResult.length"
      class="c-grid u-marginTop3 c-chat__usersList">
      <button
        v-for="user in chatStore.usersSearchResult"
        :key="user.id"
        class="c-button -flat o-flex -alignCenter -justifyStart -gap2 c-chat__userSearchListItem"
        @click="openThread(user)">
        <UserAvatar
          :user="user"
          small />
        <div
          class="t-body3"
          v-html="highlightSearchQueryPart(user.name)" />
      </button>
    </div>
    <div
      v-if="chatStore.usersSearchResult.length"
      class="c-chat__users_colleagues_divider" />
    <div class="t-h6 u-marginRight4 u-marginLeft4 u-marginBottom2 u-marginTop2">Kollegen</div>
    <div class="c-chat__usersColleagues">
      <template
        v-for="user in chatStore.colleagues">
        <UserColleagueCard
          :key="user.id"
          :user="user" />
      </template>
    </div>
  </div>
</template>

<script setup>

import { useChatStore } from '@/modules/chat/chatStore'
import UserAvatar from '@/modules/chat/components/DialogSidebar/UserAvatar.vue'
import UserColleagueCard from '@/modules/chat/components/DialogSidebar/UserColleagueCard.vue'

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

const chatStore = useChatStore()
const openThread = (user) => {
  console.log(user)
}

const highlightSearchQueryPart = (text) => {
  if (!chatStore.usersSearchQuery) {
    return text
  }
  const regex = new RegExp(chatStore.usersSearchQuery, 'gi')
  return text.replace(regex, (match) => `<b>${match}</b>`)
}

</script>

<style scoped lang="scss">
@import '@/assets/scss/config';

.c-grid {
  display: grid;
}

.c-chat__userSearchListItem {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-radius: $spacing-2;
  padding: $spacing-2 $spacing-2;
  margin: 0 $spacing-2;

  &:hover {
    background-color: #eef1f5;
  }
}

.c-chat__users_colleagues_divider {
  height: 1px;
  background-color: $color-divider;
  margin: $spacing-2 $spacing-4;
}
</style>
