<template>
  <div
    v-if="message?.text"
    class="c-chat__userLastMessage t-caption2 t-overflowEllipsis t-noWrap c-user-message-max-width o-flex -alignCenter -gap1">
    <SvgIcon
      v-if="message?.type !== 'text' && icon"
      tiny
      :type="`chat_` + icon" />
    <span>{{text}}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
})

const icon = computed(() => {
  if (props.message?.type === 'image') {
    return 'photo'
  }
  if (props.message?.type === 'file') {
    return 'attachment'
  }
  return null
})

const text = computed(() => {
  if (props.message?.type === 'image') {
    return 'Image'
  }
  if (props.message?.type === 'file') {
    return 'Attachment'
  }
  return props.message?.text
})
</script>
