import store from '@/store'

export function getParticipantDisplayName(chatThread, userId) {
  if (!chatThread) {
    return 'unknown'
  }

  const foundParticipant = chatThread.participants.find((participant) => participant.user_id === userId)

  if (!foundParticipant) {
    return 'unknown'
  }

  return foundParticipant.displayName
}
export function getParticipant(chatThread) {
  if (!chatThread) {
    return null
  }
  return chatThread.participants.find((participant) => participant.user_id !== store.getters['auth/userId'])
}
