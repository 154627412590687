<template>
  <div style="position: relative;">
    <EditorContent
      :editor="editor"
      :class="[inputClass]"
      class="c-input" />

    <EmojiDropdown
      ref="emojiDropdown"
      :show="showEmojiDropdown"
      :query="emojiQuery"
      :position="dropdownPosition"
      @select="insertDropDownEmoji" />
  </div>
</template>

<script setup>
import { Document } from '@tiptap/extension-document'
import { HardBreak } from '@tiptap/extension-hard-break'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Text } from '@tiptap/extension-text'
import { Editor, EditorContent, Extension } from '@tiptap/vue-2'
import {
  onBeforeUnmount, onMounted, ref, watch,
} from 'vue'

import EmojiDropdown from './EmojiDropdown.vue'

// To handle Pressing `Enter` when emoji dropdown is opened
const CustomKeymap = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        this.options.handleEnterKey()
        return true // Always prevent default Enter behavior
      },
    }
  },
})

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  inputClass: {
    type: String,
    default: '',
  },
  enterKeyHandler: {
    type: Function,
    default: () => {},
  },
})
const emit = defineEmits(['input'])

const editor = ref(null)
const emojiDropdown = ref(false)
const showEmojiDropdown = ref(false)
const dropdownPosition = ref({ top: '0px', left: '0px' })
const emojiQuery = ref('')

const insertDropDownEmoji = (unicode) => {
  const { selection } = editor.value.state
  const { from } = selection

  // Match the search query
  const textBeforeCursor = editor.value.state.doc.textBetween(0, from, '\n', '\n')
  const match = textBeforeCursor.match(/:(\w{2,})$/)
  if (match) {
    const startPos = from - match[0].length // Calculate the start position of the query

    // Delete the query text
    editor.value.chain()
      .focus()
      .deleteRange({ from: startPos, to: from })
      .insertContent(unicode) // Insert the emoji
      .run()
  }

  showEmojiDropdown.value = false
  emojiQuery.value = ''
}

const handleEnterKey = () => {
  if (showEmojiDropdown.value) {
    emojiDropdown.value?.selectEmoji()
  } else {
    props.enterKeyHandler?.()
  }
}

const handleClickOutside = (event) => {
  if (!emojiDropdown.value?.$el?.contains(event.target)) {
    showEmojiDropdown.value = false
  }
}

const setDropdownPosition = (editorInstance) => {
  const { from } = editorInstance.state.selection
  const startCoords = editorInstance.view.coordsAtPos(from)
  const editorCoords = editorInstance.view.dom.getBoundingClientRect()

  // Calculate the position within the editor
  const left = startCoords.left - editorCoords.left + 10

  dropdownPosition.value = {
    left: `${left}px`,
  }
}

const handleEmojiSearch = (editorInstance) => {
  const selection = editorInstance.state.selection
  const { $from } = selection

  const textBeforeCursor = $from.nodeBefore?.textContent || ''
  const match = textBeforeCursor.match(/:(\w{2,})$/)

  if (match) {
    const query = match[1].toLowerCase()
    emojiQuery.value = query

    if (query.length > 0) {
      showEmojiDropdown.value = true
      setDropdownPosition(editorInstance)
    } else {
      showEmojiDropdown.value = false
    }
  } else {
    showEmojiDropdown.value = false
  }
}

const handleKeyDown = (event) => {
  if (!showEmojiDropdown.value) {
    if (event.ctrlKey && event.code === 'Space') {
      handleEmojiSearch(editor.value)
    }

    return
  }
  if (event.code === 'Escape' && showEmojiDropdown.value) {
    showEmojiDropdown.value = false
  }
}

watch(() => props.value, (newValue) => {
  if (editor.value && editor.value.getHTML() !== newValue) {
    editor.value.commands.setContent(newValue)
  }
})

onMounted(() => {
  editor.value = new Editor({
    extensions: [
      Document,
      Paragraph,
      Text,
      HardBreak,
      CustomKeymap.configure({
        handleEnterKey,
      }),
    ],
    content: props.value,
    onUpdate: ({ editor: editorInstance }) => {
      emit('input', editorInstance.isEmpty ? '' : editorInstance.getHTML())
      handleEmojiSearch(editorInstance)
    },
  })
  document.addEventListener('click', handleClickOutside)
  document.addEventListener('keydown', handleKeyDown)
})

onBeforeUnmount(() => {
  if (editor.value) {
    editor.value.destroy()
  }
  document.removeEventListener('click', handleClickOutside)
  document.removeEventListener('keydown', handleKeyDown)
})

defineExpose({
  editor,
})
</script>
